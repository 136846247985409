<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#707487;"
      d="M386.251,134.696c-1.36,0-2.738-0.307-4.027-0.96c-4.43-2.228-6.22-7.628-3.992-12.062
	c25.914-51.481,62.416-101.168,72.294-111.042C457.342,3.817,466.387,0.039,476.01,0c0.044,0,0.097,0,0.149,0
	c9.588,0,18.58,3.72,25.335,10.479c3.509,3.509,3.509,9.197,0,12.703c-3.509,3.509-9.194,3.509-12.703,0
	c-3.387-3.387-8.097-5.242-12.711-5.216c-4.843,0.022-9.404,1.93-12.851,5.373c-7.22,7.216-42.809,54.482-68.942,106.414
	C392.708,132.889,389.541,134.696,386.251,134.696z"
    />
    <path
      style="fill:#FF507D;"
      d="M413.209,53.898H289.892c-14.294,0-28.003,5.678-38.111,15.786L5.283,316.181
	c-7.017,7.016-7.017,18.391,0,25.407l165.149,165.149c7.017,7.017,18.391,7.017,25.407,0l246.497-246.498
	c10.108-10.107,15.786-23.816,15.786-38.111V98.812C458.124,74.007,438.014,53.898,413.209,53.898z M405.316,144.816
	c-10.525,10.525-27.588,10.525-38.111,0c-10.525-10.525-10.525-27.587,0-38.111s27.588-10.525,38.111,0
	S415.84,134.293,405.316,144.816z"
    />
    <path
      style="fill:#D23C69;"
      d="M32.246,343.144L278.742,96.646c10.108-10.107,23.817-15.786,38.111-15.786H440.17
	c5.343,0,10.394,1.094,15.147,2.805c-6.234-17.315-22.646-29.767-42.109-29.767H289.892c-14.294,0-28.003,5.678-38.111,15.786
	L5.283,316.181c-7.017,7.016-7.017,18.391,0,25.407l26.962,26.962C25.229,361.536,25.229,350.16,32.246,343.144z"
    />
    <path
      style="fill:#7F8499;"
      d="M386.269,134.696c-3.29,0-6.456-1.807-8.035-4.947c-2.228-4.43-0.439-9.83,3.992-12.062
	c51.924-26.12,99.19-61.718,106.41-68.939c3.447-3.447,5.352-8.009,5.377-12.851c0.018-4.816-1.834-9.33-5.219-12.715
	s-8.097-5.242-12.711-5.216c-4.843,0.022-9.404,1.93-12.851,5.373c-3.509,3.509-9.194,3.509-12.703,0
	c-3.509-3.504-3.509-9.194,0-12.703C457.343,3.816,466.387,0.039,476.011,0c0.044,0,0.097,0,0.149,0
	c9.588,0,18.58,3.72,25.335,10.479c6.799,6.794,10.518,15.847,10.483,25.488c-0.044,9.618-3.816,18.668-10.641,25.484
	c-9.869,9.878-59.565,46.389-111.042,72.289C389.006,134.389,387.62,134.696,386.269,134.696z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M170.328,278.2c-4.386-4.386-11.491-4.386-15.878,0c-4.387,4.386-4.386,11.491,0,15.878
		l23.822,23.822l-28.585,28.585l-23.817-23.821c-4.386-4.386-11.491-4.386-15.878,0c-4.386,4.381-4.386,11.491,0,15.878
		l63.513,63.521c2.193,2.193,5.062,3.29,7.939,3.29s5.746-1.097,7.939-3.29c4.386-4.381,4.386-11.491,0-15.878l-23.817-23.821
		l28.586-28.586l23.822,23.822c2.193,2.193,5.062,3.29,7.939,3.29c2.877,0,5.746-1.097,7.939-3.29
		c4.386-4.386,4.386-11.491,0-15.878L170.328,278.2z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M262.43,230.561c-8.053-8.058-18.773-12.496-30.168-12.496s-22.115,4.439-30.168,12.492
		c-8.062,8.062-12.501,18.778-12.501,30.177c0,11.395,4.439,22.11,12.501,30.168l19.054,19.054
		c8.316,8.32,19.247,12.478,30.168,12.478c10.93,0,21.861-4.158,30.177-12.478c16.632-16.633,16.632-43.7,0-60.345L262.43,230.561z
		 M265.615,294.077c-7.869,7.869-20.703,7.886-28.589,0l-19.054-19.058c-3.816-3.815-5.921-8.891-5.921-14.286
		c0-5.4,2.105-10.474,5.921-14.294c3.815-3.815,8.895-5.918,14.291-5.918c5.395,0,10.474,2.101,14.291,5.921l19.063,19.049
		C273.492,273.375,273.492,286.2,265.615,294.077z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M348.18,227.385l-55.576-55.581l17.47-17.467c4.386-4.381,4.386-11.491,0-15.878
		c-4.386-4.387-11.491-4.386-15.878,0l-50.819,50.814c-4.386,4.381-4.386,11.491,0,15.878c2.193,2.193,5.062,3.29,7.939,3.29
		c2.877,0,5.746-1.097,7.939-3.29l17.471-17.47l55.577,55.581c2.193,2.193,5.062,3.29,7.939,3.29s5.746-1.097,7.939-3.29
		C352.566,238.881,352.566,231.771,348.18,227.385z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
